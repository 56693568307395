import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useConfigStore } from '@/common/stores'
import { api } from '@/services'

const useCompaniesStore = defineStore('companies', () => {
  const list = ref([])
  const fetchingList = ref(false)
  const currentUserId = ref(null)

  const fetchCompanyList = async (userId) => {
    if (currentUserId.value === userId) return

    const { configData } = useConfigStore()

    fetchingList.value = true
    const companyUsersCompaniesResponse = await api.get(
      `/core-services/company-users/companies?userId=${userId}`
    )
    const companyIds = companyUsersCompaniesResponse.data.data
      .filter(
        (company) => !configData.excludedCompanyIds.includes(company.company_id)
      )
      .map((company) => company.company_id)
      .join(',')
    const companiesResponse = await api.get(
      `/core-services/companies?companyIds=${companyIds}&size=100`
    )

    list.value = companiesResponse.data.data.map((company) => ({
      id: company.companyId,
      platformId: company.platformIds[0],
      jurisdiction_alpha_code:
        company.operatingJurisdictions && company.operatingJurisdictions[0],
      name: company.name,
      membership_active: true
    }))
    fetchingList.value = false
    currentUserId.value = userId
  }

  return {
    list,
    fetchingList,
    fetchCompanyList
  }
})

export default useCompaniesStore
